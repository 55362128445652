.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.anim01:focus {

  border-width: 4x;
  padding: 4px;
  background-color: grey;
  color : white ;

}


ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid #e7e7e7;
  background-color: #f3f3f3;
}

li {
  float: left;
}

li a {
  display: block;
  color: #666;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: #ddd; 
  /*background-color: white; */
}

li a.active {
  color: white;
  background-color: #04AA6D;
}

.excel {
  padding-top: 50px ;
}

.nav {

  margin-left: 50px;

  /*padding-inline: 50px ;
  padding: 50px;
  margin: 0 auto;*/
  
}

.search {

  /*display: block;*/

  padding-top: 10px ;

}

.centersearch {

  margin-top: 40px ;
  margin-left: 20%;
  margin-right: 20% ;


}

.sidenav---_u0En{
  background: lightgrey
}
